import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import chunk from 'lodash/chunk';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ExternalLink,
  H4,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const CATEGORY_FIELDS_KEYS = ['service_category', 'event_category'];
const SUB_MENU_KEYS = [
  'zenscapes',
  'service-nightly,service-hourly',
  'event',
  'podcast',
  'free-digital-download,paid-digital-download',
];

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    isLandingPage,
    isProvider,
    isAdmin,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  // const search = (
  //   <div className={css.searchContainer}>
  //     {!isLandingPage ? (
  //       <TopbarSearchForm
  //         className={css.searchLink}
  //         onSubmit={onSearchSubmit}
  //         initialValues={initialSearchFormValues}
  //         appConfig={appConfig}
  //       />
  //     ) : null}
  //   </div>
  // );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: (currentUserHasListings && isProvider) || isAdmin ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const manageListingLink =
    isProvider || isAdmin ? (
      <MenuItem key="ManageListingsPage">
        <NamedLink
          className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.yourListingsLink" />
        </NamedLink>
      </MenuItem>
    ) : (
      <MenuItem key="ManageListingsPage"></MenuItem>
    );

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {manageListingLink}
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const categoryFields = appConfig?.listing?.listingFields?.filter(f =>
    CATEGORY_FIELDS_KEYS.includes(f?.key)
  );

  const exploreMenu = () => {
    const classes = classNames(css.priorityLink);
    return SUB_MENU_KEYS.map(searchKey => {
      const name = 'SearchPage';
      const params = {};
      const to = {
        search: `?pub_listingType=${searchKey}`,
      };
      const menuConfig = appConfig?.listing?.listingFields
        ?.find(f => f.key === 'subCategory')
        ?.enumOptions?.find(e => e.parentKey === searchKey);
      const subMenuConfigs = menuConfig?.options;
      const subMenuChunks = chunk(subMenuConfigs, 10);
      const numOfChunks = subMenuChunks?.length;
      const listStyle = {
        display: 'grid',
        columnGap: '48px',
        gridTemplateColumns: `repeat(${numOfChunks}, auto)`,
      };
      const mainList = subMenuConfigs.filter(s => !(s?.options?.length > 0));
      const subLists = subMenuConfigs.filter(s => s?.options?.length > 0);
      return (
        <div className={css.priorityLinkWrapper}>
          <div className={classNames(css.menuContainer, classes)}>
            <NamedLink name={name} params={params} to={to}>
              <span className={css.priorityLinkLabel}>{menuConfig.label}</span>
            </NamedLink>
            {!!subMenuConfigs && subMenuConfigs?.length > 0 ? (
              <div className={css.subMenu}>
                <div className={css.subMenuContent}>
                  <div className={css.lists}>
                    {mainList?.length > 0 ? (
                      <div className={css.list}>
                        <NamedLink name={name} params={params} to={to} className={classes}>
                          <H4 as="h4" className={css.menuHeader}>
                            {menuConfig.label}
                          </H4>
                        </NamedLink>

                        <ul style={listStyle}>
                          {mainList.map(s => {
                            return (
                              <li>
                                <NamedLink
                                  name={name}
                                  params={params}
                                  to={{
                                    search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}`,
                                  }}
                                  className={classes}
                                >
                                  <span className={css.priorityLinkLabel}>{s.label}</span>
                                </NamedLink>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}

                    {subLists.map(s => {
                      return (
                        <div className={css.list}>
                          <NamedLink
                            name={name}
                            params={params}
                            to={{
                              search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}`,
                            }}
                            className={classes}
                          >
                            <H4 as="h4" className={css.menuHeader}>
                              {s.label}
                            </H4>
                          </NamedLink>

                          <ul style={listStyle}>
                            {s.options.map(ss => {
                              return (
                                <li>
                                  <NamedLink
                                    name={name}
                                    params={params}
                                    to={{
                                      search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}&pub_group=${ss.value}`,
                                    }}
                                    className={classes}
                                  >
                                    <span className={css.priorityLinkLabel}>{ss.label}</span>
                                  </NamedLink>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                  <div className={css.images}>
                    {/* <img className={css.image} src={IMAGES[searchKey][0]} /> */}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    });
  };

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <div className={css.topbarContainer}>
      <nav className={classes}>
        <LinkedLogo
          className={css.logoLink}
          layout="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        />
        <div className={css.navLinks}>
          {exploreMenu()}
          {isAdmin || isProvider ? (
            <NamedLink className={css.createListingLink} name="NewListingPage">
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.createListing" />
              </span>
            </NamedLink>
          ) : (
            <ExternalLink
              className={css.createListingLink}
              href={process.env.REACT_APP_CONTACT_FORM_LINK}
            >
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.makeZenpage" />
              </span>
            </ExternalLink>
          )}
          {inboxLink}
          {profileMenu}
          {signupLink}
          {loginLink}
        </div>
      </nav>
    </div>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
