/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';
import sortBy from 'lodash/sortBy';
import IconPlus from '../../../SearchPage/IconPlus/IconPlus';

import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  ExternalLink,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';

const CATEGORY_FIELDS_KEYS = ['service_category', 'event_category'];
const SUB_MENU_KEYS = [
  'zenscapes',
  'service-nightly,service-hourly',
  'event',
  'podcast',
  'free-digital-download,paid-digital-download',
];

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    isProvider,
    isAdmin,
    config,
  } = props;
  const [openFilters, setOpenFilters] = useState([]);

  const toggleIsOpen = filterId => {
    if (openFilters.includes(filterId)) {
      setOpenFilters(openFilters.filter(o => o !== filterId));
    } else {
      setOpenFilters([...openFilters, filterId]);
    }
  };
  
  const user = ensureCurrentUser(currentUser);

  const categoryFields = config?.listing?.listingFields?.filter(f =>
    CATEGORY_FIELDS_KEYS.includes(f?.key)
  );

  const exploreMenu = () => {
    const classes = classNames(css.priorityLink);
    return SUB_MENU_KEYS.map(searchKey => {
      const name = 'SearchPage';
      const params = {};
      const to = {
        search: `?pub_listingType=${searchKey}`,
      };
      const className = classNames(css.navigationLink);
      const menuConfig = config?.listing?.listingFields
        ?.find(f => f.key === 'subCategory')
        ?.enumOptions?.find(e => e.parentKey === searchKey);
      let subMenuConfigs = menuConfig?.options;
      subMenuConfigs = sortBy(subMenuConfigs, [
        function(s) {
          return s?.options?.length > 0;
        },
      ]);
      return (
        <div className={classNames(className, css.subMenuLink)}>
          <div
            className={classNames(css.priorityLinkLabel, css.groupLabel)}
            onClick={() => toggleIsOpen(menuConfig.label)}
          >
            <span className={css.label}>{menuConfig.label}</span>
            <span className={css.openSign}>
              <IconPlus isOpen={openFilters.includes(menuConfig.label)} isSelected={false} />
            </span>
          </div>
          {!!subMenuConfigs && subMenuConfigs?.length > 0 ? (
            <ul className={classNames(css.subMenu, { [css.isOpen]: openFilters.includes(menuConfig.label) })}>
              {subMenuConfigs.map((s, index) => {
                if (s?.options?.length > 0) {
                  return (
                    <ul
                      className={classNames(css.subMenu, {
                        [css.isOpen]: openFilters.includes(menuConfig.label),
                      })}
                      key={`${menuConfig.label}.${index}`}
                    >
                      {index === 0 ? (
                        <li key={`${s.option}.all`} className={css.allLink}>
                          <NamedLink name={name} params={params} to={to}>
                            <span className={css.priorityLinkLabel}>{`All ${menuConfig.label}`}</span>
                          </NamedLink>
                        </li>
                      ) : null}
                      <li key={`${s.option}`}>
                        <NamedLink
                          name={name}
                          params={params}
                          to={{
                            search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}`,
                          }}
                          className={classNames(className, css.subMenuLabel)}
                        >
                          <span className={css.priorityLinkLabel}>{s.label}</span>
                        </NamedLink>
                      </li>
                      {s.options.map((group, index) => {
                        return (
                          <li key={`${group.value}`}>
                            <NamedLink
                              name={name}
                              params={params}
                              to={{
                                search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}&pub_group=${group.value}`,
                              }}
                              className={classNames(className, css.subMenuLink)}
                            >
                              <span className={css.priorityLinkLabel}>{group.label}</span>
                            </NamedLink>
                          </li>
                        );
                      })}
                    </ul>
                  );
                } else {
                  return index === 0 ? (
                    <>
                      <li key={`${s.option}.all`} className={css.allLink}>
                        <NamedLink name={name} params={params} to={to}>
                          <span className={css.priorityLinkLabel}>{`All ${menuConfig.label}`}</span>
                        </NamedLink>
                      </li>
                      <li key={`${s.option}`}>
                        <NamedLink
                          name={name}
                          params={params}
                          to={{
                            search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}`,
                          }}
                          className={classNames(className, css.subMenuLink)}
                        >
                          <span className={css.priorityLinkLabel}>{s.label}</span>
                        </NamedLink>
                      </li>
                    </>
                  ) : (
                    <li key={`${s.option}`}>
                      <NamedLink
                        name={name}
                        params={params}
                        to={{
                          search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}`,
                        }}
                        className={classNames(className, css.subMenuLink)}
                      >
                        <span className={css.priorityLinkLabel}>{s.label}</span>
                      </NamedLink>
                    </li>
                  );
                }
              })}
            </ul>
          ) : null}
        </div>
      );
    });
  };

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          {exploreMenu()}
        </div>
        <div className={css.footer}>
          {isAdmin || isProvider ? (
            <NamedLink className={css.createNewListingLink} name="NewListingPage">
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </NamedLink>
          ) : (
            <ExternalLink
              className={css.createNewListingLink}
              href={process.env.REACT_APP_CONTACT_FORM_LINK}
            >
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </ExternalLink>
          )}
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings && isProvider ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {isProvider || isAdmin ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        ) : null}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        {exploreMenu()}
        <div className={css.spacer} />
      </div>
      {isAdmin || isProvider ? (
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      ) : (
        <ExternalLink
          className={css.createNewListingLink}
          href={process.env.REACT_APP_CONTACT_FORM_LINK}
        >
          <FormattedMessage id="TopbarDesktop.makeZenpage" />
        </ExternalLink>
      )}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
